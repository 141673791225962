import dashboard from './dashboard'
import namePathAdminCallCenter from './name-path-admin-call-center';
import namePathAdminSuper from './name-path-admin-super'
import namePathAdminTukangPro from './name-path-admin-tukang-pro';

// Array of sections
var sections = [];
var role_name = JSON.parse(localStorage.getItem('userData'));
if(role_name) {
	switch (role_name.role_name) {
		case "Super Admin":
			sections = [...dashboard, ...namePathAdminSuper];
			break;
		case "Admin Call Center":
			sections = [...dashboard, ...namePathAdminCallCenter];
			break;
		case "Admin Tukang Pro":
			sections = [...dashboard, ...namePathAdminTukangPro];
			break;
		default:
			break;
	}
}

export default sections;